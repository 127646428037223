import { TransferSettings, Account } from '@prisma/client';
import { ResponseData, SuccessResponse } from '../server/general';
import { AuthTypeEnum } from '../types/general';
import { PatternAndReferenceImage } from '../types/pattern';
import { customFetch } from '../utils';

export const fetchCurrentUser = async () => {
  const responseData = await customFetch<ResponseData<Account>>('/api/user/me');
  return responseData.data;
};

export const fetchCurrentTranferSettings = async () => {
  const responseData = await customFetch<ResponseData<TransferSettings>>(
    '/api/user/me/transfer-settings'
  );
  return responseData.data;
};

export const updateCurrentTransferSettings = async (
  payload: Partial<TransferSettings>
) => {
  const responseData = await customFetch<ResponseData<TransferSettings>>(
    '/api/user/me/transfer-settings',
    {
      method: 'PUT',
      body: JSON.stringify(payload),
    }
  );
  return responseData.data;
};

export const fetchTempPattern = async () => {
  const responseData = await customFetch<
    ResponseData<PatternAndReferenceImage | null>
  >('/api/user/me/temp-pattern');
  return responseData?.data;
};

export const loginAudit = async (accountId: number, authType: AuthTypeEnum) => {
  const responseData = await customFetch<SuccessResponse>(
    '/api/user/login-audit',
    {
      method: 'PATCH',
      body: JSON.stringify({
        accountId,
        authType,
      }),
    }
  );
  return responseData.success;
};
