import { PaletteDetail } from '@prisma/client';
import { ResponseList } from '../server/general';
import { PaletteEx, PaletteWithDetails } from '../types/palette';
import { customFetch } from '../utils';

export const fetchPalettes = async () => {
  const responseData = await customFetch<ResponseList<PaletteEx>>(
    '/api/palette',
    {
      method: 'GET',
    }
  );
  return responseData.items;
};

export const fetchPalette = async (
  paletteId: number,
  isCustomPalette: boolean
) => {
  return await customFetch<PaletteWithDetails>(
    `/api/palette/${paletteId}?isCustomPalette=${isCustomPalette}`,
    {
      method: 'GET',
    }
  );
};

export const fetchPaletteDetails = async (
  id: number,
  filter: string,
  limit: number
) => {
  const responseData = await customFetch<ResponseList<PaletteDetail>>(
    `/api/palette/${id}/details?filter=${filter}&limit=${limit}`,
    {
      method: 'GET',
    }
  );
  return responseData.items;
};
