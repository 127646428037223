import { CustomPalette } from '@prisma/client';
import { ResponseData, ResponseList } from '../server/general';
import {
  CustomPaletteDetailData,
  CustomPaletteWithDetails,
} from '../types/palette';
import { customFetch } from '../utils';

export const fetchCustomPalettes = async () => {
  const responseData = await customFetch<
    ResponseList<CustomPaletteWithDetails>
  >('/api/custom-palette', {
    method: 'GET',
  });
  return responseData.items;
};

export const createCustomPalette = async (
  name: string
): Promise<CustomPalette | undefined> => {
  const responseData = await customFetch<ResponseData<CustomPalette>>(
    '/api/custom-palette',
    {
      method: 'POST',
      body: JSON.stringify({
        name: name,
      }),
    }
  );

  return responseData.data;
};

export const updateCustomPalette = async (
  id: number,
  name: string
): Promise<CustomPalette | undefined> => {
  const responseData = await customFetch<ResponseData<CustomPalette>>(
    `/api/custom-palette/${id}`,
    {
      method: 'PATCH',
      body: JSON.stringify({
        name: name,
      }),
    }
  );

  return responseData.data;
};

export const deleteCustomPalette = async (id: number) => {
  await customFetch(`/api/custom-palette/${id}`, {
    method: 'DELETE',
  });
};

export const addCustomPaletteDetail = async (
  customPaletteId: number,
  paletteDetailIds: number[]
) => {
  const responseData = await customFetch<
    ResponseData<CustomPaletteWithDetails>
  >(`/api/custom-palette/${customPaletteId}/detail`, {
    method: 'PATCH',
    body: JSON.stringify({
      paletteDetailIds: paletteDetailIds,
    }),
  });

  return responseData.data;
};

export const removeCustomPaletteDetail = async (
  customPaletteId: number,
  customPaletteDetailId: number
) => {
  await customFetch(
    `/api/custom-palette/${customPaletteId}/detail/${customPaletteDetailId}`,
    {
      method: 'DELETE',
    }
  );
};

export const createPaletteDetail = async (
  customPaletteId: number,
  name: string,
  description: string,
  paletteTypeId: number,
  color: string
) => {
  const responseData = await customFetch<ResponseData<CustomPaletteDetailData>>(
    `/api/custom-palette/${customPaletteId}/detail`,
    {
      method: 'POST',
      body: JSON.stringify({
        customPaletteId,
        name,
        description,
        paletteTypeId,
        color,
      }),
    }
  );

  return responseData.data;
};

export const updatePaletteDetail = async (
  customPaletteId: number,
  customPaletteDetailId: number,
  name: string,
  description: string,
  paletteTypeId: number,
  color: string
) => {
  const responseData = await customFetch<ResponseData<CustomPaletteDetailData>>(
    `/api/custom-palette/${customPaletteId}/detail/${customPaletteDetailId}`,
    {
      method: 'PUT',
      body: JSON.stringify({
        customPaletteId,
        customPaletteDetailId,
        name,
        description,
        paletteTypeId,
        color,
      }),
    }
  );

  return responseData.data;
};
