import React from 'react';

export interface ScrollbarProps {
  className: string;
  children: React.ReactNode;
}

const Scrollbar = ({ className, children }: ScrollbarProps) => {
  return (
    <div
      className={`scrollbar-xs overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-transparent scrollbar-thumb-scrollbar scrollbar-thumb-rounded-[5px] ${className}`}
    >
      {children}
    </div>
  );
};

export default Scrollbar;
