/* eslint-disable @next/next/no-img-element */
export interface IconButtonProps {
  src: string;
  alt: string;
  className?: string;
  onClick?: () => void;
}

export default function IconButton({
  src,
  alt,
  className,
  onClick,
}: IconButtonProps) {
  return (
    <img
      src={src}
      alt={alt}
      className={`h-4 w-4 cursor-pointer ${className || ''}`}
      onClick={onClick}
    />
  );
}
