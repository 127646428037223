import { PatternSize, StitchTypeEnum } from '@/lib/types/general';
import { GridData } from '@/lib/types/palette';
import { Category, Pattern, PatternReferenceImage } from '@prisma/client';
import { atom } from 'jotai';
import { EMPTY_CATEGORY, INITIAL_STATE } from '../constants';

export const patternZoomAtom = atom(1);
export const patternPositionAtom = atom(INITIAL_STATE.patternPosition);

export const StitchTypeMapping = {
  [StitchTypeEnum.Peyote]: 'Peyote',
  [StitchTypeEnum.Brick]: 'Brick',
  [StitchTypeEnum.Loom]: 'Loom',
};

export const selectedStitchTypeAtom = atom(StitchTypeEnum.Peyote);

export const patternSizeAtom = atom<PatternSize>(INITIAL_STATE.patternSize);

export const patternAtom = atom<Pattern | null>(null);
export const patternSavedAtom = atom(true);
export const gridAtom = atom<GridData>([]);

export const categoriesAtom = atom<Category[]>([]);
export const selectedCategoryAtom = atom<Category>(EMPTY_CATEGORY);

export const patternReferenceImageAtom = atom<PatternReferenceImage | null>(
  null
);

export const patternPreviewsAtom = atom<Record<number, string>>({});
