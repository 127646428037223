import { useState, useEffect, useCallback } from 'react';

const mobileBreakpoint = 640;

const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' && window.innerWidth < mobileBreakpoint
  );

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < mobileBreakpoint);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return isMobile;
};

export default useIsMobile;
