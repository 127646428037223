/* eslint-disable jsx-a11y/alt-text */
import { gridAtom, patternAtom } from '@/lib/atoms/pattern';
import { PatternPainter } from '@/lib/canvas/pattern-painter';
import { MAX_N_CELLS_PER_LINE_WORD_CHART } from '@/lib/constants';
import { StitchTypeEnum } from '@/lib/types/general';
import {
  BeadChartCell,
  generateBeadChart,
  getCanvasSize,
  getPaletteBeadColors,
} from '@/lib/utils';
import {
  Document,
  Font,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import invert from 'invert-color';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

const convertToPdfPixels = (designPixels: number) => {
  return (designPixels * 595.28) / 1785;
};

export const PrintPatternDocument = () => {
  const pattern = useAtomValue(patternAtom);
  const grid = useAtomValue(gridAtom);

  const paletteBeadColors = useMemo(() => {
    if (!pattern) return null;
    return getPaletteBeadColors(grid, pattern?.cols || 0, pattern?.rows || 0);
  }, [grid, pattern]);

  const beadsCount = useMemo(() => {
    if (!grid) return null;
    return grid.reduce((acc, row) => {
      return acc + row?.filter((bead) => bead?.id).length || 0;
    }, 0);
  }, [grid]);

  const emptyBeadsCount = useMemo(() => {
    if (!grid) return 0;
    return grid.reduce((acc, row) => {
      return acc + row?.filter((bead) => !bead).length || 0;
    }, 0);
  }, [grid]);

  const patternImage = useMemo(() => {
    const zoom = 3.0;

    if (!pattern) return;

    const canvasSize = getCanvasSize(
      { cols: pattern.cols, rows: pattern.rows },
      pattern.stitchTypeId as StitchTypeEnum,
      zoom
    );

    const canvas = document.createElement('canvas');
    canvas.width = canvasSize.width;
    canvas.height = canvasSize.height;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const painter = new PatternPainter(
      canvas,
      ctx,
      pattern.stitchTypeId as StitchTypeEnum,
      pattern.cols,
      pattern.rows,
      grid,
      zoom,
      true
    );

    if (!painter) return;

    return painter.getPatternImage();
  }, [grid, pattern]);

  const wordChartRows = useMemo(() => {
    if (!pattern || !grid || grid.length === 0) return null;
    return generateBeadChart(pattern, grid);
  }, [grid, pattern]);

  const getLines = (cells: BeadChartCell[]): BeadChartCell[][] => {
    const lines: BeadChartCell[][] = [];
    for (let i = 0; i < cells.length; i += MAX_N_CELLS_PER_LINE_WORD_CHART) {
      const line = cells.slice(i, i + MAX_N_CELLS_PER_LINE_WORD_CHART);
      lines.push(line);
    }
    return lines;
  };

  if (
    !pattern ||
    !grid ||
    !paletteBeadColors ||
    !beadsCount ||
    !patternImage ||
    !wordChartRows
  )
    return <Document></Document>;

  const pageNumber = () => (
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  );

  const getStitchAssemblyGuidanceImageUrl = () => {
    switch (pattern.stitchTypeId) {
      case StitchTypeEnum.Brick:
        return '/assets/pdf/brick-stitch.png';
      case StitchTypeEnum.Loom:
        return '/assets/pdf/loom-stitch.png';
      case StitchTypeEnum.Peyote:
      default:
        return '/assets/pdf/peyote-stitch.png';
    }
  };

  return (
    <Document>
      <Page size='A4' style={styles.body}>
        <View
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: convertToPdfPixels(24),
          }}
        >
          <View
            style={{
              width: convertToPdfPixels(1200),
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#F6F6F6',
              borderRadius: convertToPdfPixels(40),
              padding: convertToPdfPixels(64),
              gap: convertToPdfPixels(32),
            }}
          >
            <Image
              src='/assets/pdf/logo.png'
              style={{ width: convertToPdfPixels(285) }}
            />
            <Text
              style={{
                maxWidth: convertToPdfPixels(650),
                fontSize: convertToPdfPixels(64),
                fontWeight: 500,
                color: '#1A1919',
              }}
            >
              {pattern?.name}
            </Text>
            <Text
              style={{
                fontSize: convertToPdfPixels(32),
                fontWeight: 500,
                color: '#666666',
              }}
            >
              PDF Guide export
            </Text>
            <Link
              style={{
                fontSize: convertToPdfPixels(20),
                fontWeight: 500,
                color: '#666666',
                textDecoration: 'none',
              }}
              src='www.beadpatternmaker.com'
            >
              www.beadpatternmaker.com
            </Link>
          </View>
          <View
            style={{
              border: `${convertToPdfPixels(1)}px solid #EDEDED`,
              width: convertToPdfPixels(1200),
              height: convertToPdfPixels(374),
              borderRadius: convertToPdfPixels(40),
              padding: convertToPdfPixels(64),
              gap: convertToPdfPixels(32),
            }}
          >
            <Text style={{ fontSize: convertToPdfPixels(48), fontWeight: 500 }}>
              {`${
                paletteBeadColors?.length || 0
              } colors and ${beadsCount?.toLocaleString()} beads`}
            </Text>
            <View
              style={{
                width: convertToPdfPixels(239),
                height: convertToPdfPixels(60),
                backgroundColor: '#F6F6F6',
                borderRadius: convertToPdfPixels(16),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text
                style={{
                  fontSize: convertToPdfPixels(20),
                  fontWeight: 500,
                }}
              >
                Miyuki Delica beads
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {paletteBeadColors
                ?.filter((_, index) => index < 6)
                .map((b) => b.color)
                .map((color, index) => (
                  <View
                    key={index}
                    style={{
                      border: `${convertToPdfPixels(4)}px solid white`,
                      borderRadius: convertToPdfPixels(16),
                      width: convertToPdfPixels(60),
                      height: convertToPdfPixels(60),
                      backgroundColor: color,
                      marginLeft: convertToPdfPixels(-16),
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text
                      style={{
                        color: invert(color, true),
                        fontSize: convertToPdfPixels(18),
                        fontWeight: 500,
                      }}
                    >
                      {index === 5 ? `+${paletteBeadColors.length - 5}` : ''}
                    </Text>
                  </View>
                ))}
            </View>
          </View>
          <View
            style={{
              border: `${convertToPdfPixels(1)}px solid #EDEDED`,
              width: convertToPdfPixels(1200),
              height: convertToPdfPixels(528),
              borderRadius: convertToPdfPixels(40),
              padding: convertToPdfPixels(64),
              gap: convertToPdfPixels(32),
            }}
          >
            <Image
              src={getStitchAssemblyGuidanceImageUrl()}
              style={{ width: convertToPdfPixels(523) }}
            />
          </View>
        </View>
        {pageNumber()}
      </Page>
      <Page
        size='A4'
        style={styles.body}
        orientation={pattern.cols > pattern.rows ? 'landscape' : 'portrait'}
      >
        <View
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            src={patternImage}
            style={{
              width:
                pattern.cols > pattern.rows
                  ? convertToPdfPixels(2300)
                  : undefined,
              height:
                pattern.cols > pattern.rows
                  ? undefined
                  : convertToPdfPixels(1700),
            }}
          />
        </View>
        {pageNumber()}
      </Page>
      <Page size='A4' wrap style={styles.body}>
        <Text
          style={{
            textAlign: 'center',
            marginBottom: convertToPdfPixels(24),
            fontSize: convertToPdfPixels(48),
            fontWeight: 500,
          }}
        >
          Bead legenda
        </Text>
        <View style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <View
            style={{
              width: convertToPdfPixels(1400),
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: convertToPdfPixels(8),
            }}
          >
            {paletteBeadColors.map((beadColor) => (
              <View
                key={beadColor.name}
                style={{
                  width: convertToPdfPixels(193),
                  height: convertToPdfPixels(80),
                  padding: convertToPdfPixels(16),
                  border: `${convertToPdfPixels(1)}px solid #DEDEDE`,
                  borderRadius: convertToPdfPixels(8),
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: convertToPdfPixels(8),
                  }}
                >
                  <View
                    style={{
                      width: convertToPdfPixels(40),
                      height: convertToPdfPixels(48),
                      borderRadius: convertToPdfPixels(4),
                      backgroundColor: beadColor.color,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: convertToPdfPixels(24),
                        fontWeight: 500,
                        color: invert(beadColor.color, true),
                        display: 'flex',
                        alignSelf: 'center',
                      }}
                    >
                      {beadColor.label}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      gap: convertToPdfPixels(8),
                    }}
                  >
                    <Text
                      style={{
                        fontSize: convertToPdfPixels(12),
                        fontWeight: 500,
                        color: '#1A1919',
                      }}
                    >
                      {beadColor.name}
                    </Text>
                    <Text
                      style={{
                        fontSize: convertToPdfPixels(10),
                        fontWeight: 500,
                        color: '#808080',
                      }}
                    >
                      {beadColor.count.toLocaleString()} beads
                    </Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
        </View>
        <Text
          style={{
            textAlign: 'center',
            marginTop: convertToPdfPixels(100),
            marginBottom: convertToPdfPixels(24),
            fontSize: convertToPdfPixels(48),
            fontWeight: 500,
          }}
        >
          Word chart
        </Text>
        {emptyBeadsCount > 0 && (
          <View
            style={{
              width: '100%',
              height: 60,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              src='/assets/pdf/brick-empty-cells-legenda.png'
              style={{ width: convertToPdfPixels(1400) }}
            />
          </View>
        )}
        <View
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: convertToPdfPixels(16),
          }}
        >
          {wordChartRows.map((row) => (
            <View
              key={row.rowName}
              wrap={false}
              style={{
                width: convertToPdfPixels(1400),
                display: 'flex',
                flexDirection: 'column',
                gap: convertToPdfPixels(24),
                border: `${convertToPdfPixels(1)}px solid #DEDEDE`,
                borderRadius: convertToPdfPixels(16),
                padding: convertToPdfPixels(32),
              }}
            >
              <Text
                style={{
                  fontSize: convertToPdfPixels(32),
                  fontWeight: 500,
                  color: '#1A1919',
                }}
              >
                {row.rowName}
              </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                {getLines(row.cells).map((line, lineIndex) => (
                  <View
                    key={lineIndex}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: convertToPdfPixels(10),
                      backgroundColor: lineIndex % 2 == 0 ? '#F6F6F6' : '',
                      width: convertToPdfPixels(1337),
                      height: convertToPdfPixels(60),
                      borderRadius: convertToPdfPixels(8),
                      padding: convertToPdfPixels(16),
                    }}
                  >
                    {line.map((cell, cellIndex) => (
                      <Text
                        key={`${lineIndex}-${cellIndex}`}
                        style={{
                          paddingLeft: convertToPdfPixels(8),
                          width: convertToPdfPixels(84),
                          height: convertToPdfPixels(30),
                          fontSize: convertToPdfPixels(20),
                          fontWeight: 500,
                          color: '#1A1919',
                          display: 'flex',
                          alignSelf: 'center',
                        }}
                      >
                        {cell.letter}({cell.count})
                      </Text>
                    ))}
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>
        {pageNumber()}
      </Page>
    </Document>
  );
};

Font.register({
  family: 'Inter',
  fontWeight: 500,
  src: 'assets/fonts/Inter-Medium.ttf',
});

Font.register({
  family: 'Inter',
  fontWeight: 400,
  src: 'assets/fonts/Inter-Regular.ttf',
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Inter',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});
