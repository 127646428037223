'use client';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import React from 'react';
import { Fragment } from 'react';

export interface MyMenuItem {
  value: string;
  label: string;
  icon?: React.JSX.Element;
  onClick?: () => void;
}

export interface MyMenuProps {
  children: React.ReactNode;
  items: MyMenuItem[];
}

const MyMenu = ({ children, items }: MyMenuProps) => {
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <MenuButton>{children}</MenuButton>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <MenuItems className='absolute right-0 mt-2 origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          {items.map((item) => (
            <MenuItem key={item.value}>
              <button
                className='group flex w-full items-center rounded-md px-2 py-2 text-2xs font-medium'
                onClick={() => item.onClick?.()}
              >
                {item.icon}
                {item.label}
              </button>
            </MenuItem>
          ))}
        </MenuItems>
      </Transition>
    </Menu>
  );
};

export default MyMenu;
