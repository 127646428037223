import {
  DEFAULT_FILTER_DEBOUNCE_TIME,
  COLOR_SECONDARY,
  COLOR_LINK,
  EMPTY_CATEGORY,
} from '@/lib/constants';
import useDebounce from '@/lib/hooks/useDebounce';
import { onDeleteCategory } from '@/lib/utils';
import { useCallback, useEffect, useState } from 'react';
import Textbox from '../general/Textbox';
import CloseIcon from '../icons/CloseIcon';
import DeleteIcon from '../icons/DeleteIcon';
import EditIcon from '../icons/EditIcon';
import PlusIcon from '../icons/PlusIcon';
import SearchIcon from '../icons/SearchIcon';
import HorizontalDivider from '../other/HorizontalDivider';
import {
  confirmModalOptionsAtom,
  alertModalOptionsAtom,
  createCategoryModalVisibleAtom,
  categoryEditorVisibleAtom,
  paletteEditorPopoverVisibleAtom,
} from '@/lib/atoms/general';
import { categoriesAtom, selectedCategoryAtom } from '@/lib/atoms/pattern';
import { Category } from '@prisma/client';
import { useSetAtom, useAtom } from 'jotai';
import { fetchCategories } from '@/lib/api/category';
import { useSession } from 'next-auth/react';

const CategoryEditorComponent = () => {
  const session = useSession();
  const [categoryEditorVisible, setCategoryEditorVisible] = useAtom(
    categoryEditorVisibleAtom
  );
  const setPaletteEditorPopoverVisible = useSetAtom(
    paletteEditorPopoverVisibleAtom
  );
  const setCreateCategoryModalVisible = useSetAtom(
    createCategoryModalVisibleAtom
  );
  const setConfirmModalOptions = useSetAtom(confirmModalOptionsAtom);
  const setAlertModalOptions = useSetAtom(alertModalOptionsAtom);

  const [categories, setCategories] = useAtom(categoriesAtom);
  const setSelectedCategory = useSetAtom(selectedCategoryAtom);

  const onCreateCategory = () => {
    setCreateCategoryModalVisible(null);
  };

  const onEditCategory = (category: Category) => {
    setCreateCategoryModalVisible(category);
  };

  const [filter, setFilter] = useState<string>('');
  const debouncedFilter = useDebounce(filter, DEFAULT_FILTER_DEBOUNCE_TIME);

  const refreshCategories = useCallback(async () => {
    const newCategories = await fetchCategories('', true);
    setCategories(newCategories);
  }, [setCategories]);

  useEffect(() => {
    if (session.status === 'authenticated') {
      refreshCategories();
    }
  }, [refreshCategories, session]);

  const togglePopover = () => {
    const newCategoryEditorPopoverVisible = !categoryEditorVisible;
    setCategoryEditorVisible(newCategoryEditorPopoverVisible);
    if (newCategoryEditorPopoverVisible) {
      setPaletteEditorPopoverVisible(false);
    } else {
      setTimeout(() => {
        setSelectedCategory(EMPTY_CATEGORY);
      }, 500);
    }
  };

  return (
    <div className='z-30 flex flex-col rounded-lg border border-divider bg-white pb-4 shadow-md sm:w-[400px]'>
      <div className='flex flex-row items-center justify-between px-4 py-2'>
        <div className='text-sm font-medium'>Manage categories</div>
        <CloseIcon className='cursor-pointer' onClick={() => togglePopover()} />
      </div>
      <HorizontalDivider />
      <Textbox
        text={filter}
        onChange={setFilter}
        placeholder='Search categories'
        icon={<SearchIcon strokeColor={COLOR_SECONDARY} />}
        className='border-0 px-4 py-2'
        fontSize={'large'}
        autoFocus
        selectAll
      />
      <HorizontalDivider />
      <div
        className='mx-4 mb-1 mt-4 flex h-6 cursor-pointer flex-row items-center gap-2'
        onClick={() => onCreateCategory()}
      >
        <PlusIcon className='cursor-pointer' strokeColor={COLOR_LINK} />
        <div className='text-xs font-medium leading-6 text-link'>
          Create new category
        </div>
      </div>
      <div className='flex flex-col gap-1 px-4 pt-1'>
        {categories
          .filter(
            (d) =>
              debouncedFilter === '' ||
              d.name.toLowerCase().includes(debouncedFilter.toLowerCase())
          )
          .map((category) => (
            <div
              key={category.id}
              className='flex h-6 cursor-pointer select-none flex-row items-center justify-between'
            >
              <div className='flex flex-row gap-3'>
                <div className='text-xs font-medium text-primary'>
                  {category.name}
                </div>
              </div>
              <div className='flex flex-row gap-4'>
                <EditIcon onClick={() => onEditCategory(category)} />
                <DeleteIcon
                  onClick={() =>
                    onDeleteCategory(
                      category,
                      setConfirmModalOptions,
                      setAlertModalOptions,
                      refreshCategories,
                      setSelectedCategory
                    )
                  }
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CategoryEditorComponent;
