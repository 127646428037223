import { DropdownItem } from '@/app/components/general/Dropdown';
import {
  AlertModalOptions,
  ConfirmModalOptions,
  FloatingToolbarType,
  HistoryData,
  HistoryDataItem,
  PatternSettingsModalOptionEnum,
  SelectedContextEnum,
  ToolbarMenuItemEnum,
  UploadImage,
  UploadImageTriggerTypeEnum,
  WizardModalOptions,
  WizardStep,
} from '@/lib/types/general';
import { Category, TransferSettings, Account } from '@prisma/client';
import { Setter, atom } from 'jotai';
import {
  DEFAULT_TOOL,
  DEFAULT_UOM,
  INITIAL_STATE,
  WIZARD_STEPS,
  unitOfMeasuresOptions,
} from '../constants';
import { CustomPaletteWithDetails } from '../types/palette';
import { deepClone } from '../utils';
import {
  gridAtom,
  patternPositionAtom,
  patternSizeAtom,
  patternZoomAtom,
  selectedStitchTypeAtom,
} from './pattern';

export const selectedToolAtom = atom<FloatingToolbarType>(DEFAULT_TOOL);

export const selectedUomAtom = atom<DropdownItem>(DEFAULT_UOM);
export const selectedContextAtom = atom<SelectedContextEnum>(
  SelectedContextEnum.None
);
export const selectedToolbarMenuItemAtom = atom<ToolbarMenuItemEnum>(
  ToolbarMenuItemEnum.Save
);

export const newPatternModalVisibleAtom = atom<boolean>(false);
export const openPatternModalVisibleAtom = atom<boolean>(false);
export const savePatternModalVisibleAtom = atom<boolean>(false);
export const selectPaletteDetailModalVisibleAtom = atom<boolean>(false);
export const transferModalVisibleAtom = atom<boolean>(false);
export const patternSettingsModalOptionAtom = atom<
  PatternSettingsModalOptionEnum | undefined
>(undefined);
export const triggerImageUploaderAtom = atom<
  UploadImageTriggerTypeEnum | undefined
>(undefined);
export const uploadImageAtom = atom<UploadImage | undefined>(undefined);
export const paletteEditorPopoverVisibleAtom = atom<boolean>(false);
export const createPaletteModalVisibleAtom = atom<
  CustomPaletteWithDetails | null | undefined
>(undefined);
export const addRemoveToCustomPaletteVisibleAtom = atom<
  CustomPaletteWithDetails | undefined
>(undefined);
export const previewPDFModalVisibleAtom = atom<boolean>(false);
export const confirmModalOptionsAtom = atom<ConfirmModalOptions | undefined>(
  undefined
);
export const alertModalOptionsAtom = atom<AlertModalOptions | undefined>(
  undefined
);

export const wizardModalStepsAtom = atom<WizardStep[]>(WIZARD_STEPS);

export const wizardModalOptionsAtom = atom<WizardModalOptions | undefined>(
  undefined
);

export const categoryEditorVisibleAtom = atom<boolean>(false);
export const createCategoryModalVisibleAtom = atom<Category | null | undefined>(
  undefined
);

export const planSelectionModalVisibleAtom = atom<boolean>(false);

export const currentUserAtom = atom<Account | undefined>(undefined);
export const currentTransferSettingsAtom = atom<TransferSettings | undefined>(
  undefined
);

export const historyAtom = atom<HistoryData>({
  past: [],
  present: deepClone(INITIAL_STATE),
  future: [],
});

const updateState = (set: Setter, state: HistoryDataItem) => {
  set(selectedStitchTypeAtom, state.stitchType);
  set(patternSizeAtom, state.patternSize);
  set(patternPositionAtom, state.patternPosition);
  set(
    selectedUomAtom,
    unitOfMeasuresOptions.find((uom) => uom.value === state.unitOfMeasureId)!
  );
  set(gridAtom, state.grid);
  set(patternZoomAtom, state.patternZoom);
};

export const undoAtom = atom(
  (get) => get(historyAtom).past.length > 0,
  (get, set) => {
    const { past, present, future } = get(historyAtom);
    if (past.length > 0) {
      const previous = past[past.length - 1];
      const newPast = past.slice(0, past.length - 1);
      set(historyAtom, {
        past: deepClone(newPast),
        present: deepClone(previous),
        future: deepClone([present, ...future]),
      });
      updateState(set, previous);
    }
  }
);

export const redoAtom = atom(
  (get) => get(historyAtom).future.length > 0,
  (get, set) => {
    const { past, present, future } = get(historyAtom);
    if (future.length > 0) {
      const next = future[0];
      const newFuture = future.slice(1);
      set(historyAtom, {
        past: deepClone([...past, present]),
        present: deepClone(next),
        future: deepClone(newFuture),
      });
      updateState(set, next);
    }
  }
);
