import React, { MouseEventHandler } from 'react';

export enum TooltipPosition {
  Top,
  Bottom,
}

export interface TooltipProps {
  id?: string;
  message: string;
  position?: TooltipPosition;
  children: React.ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
}

const Tooltip = ({
  id,
  message,
  position = TooltipPosition.Bottom,
  children,
  className,
  onClick,
}: TooltipProps) => {
  return (
    <div
      id={id}
      className={`group relative flex ${className || ''}`}
      onClick={onClick}
    >
      {children}
      <span
        className={`absolute ${
          position === TooltipPosition.Top ? '-top-10' : 'top-10'
        } z-50 scale-0 select-none whitespace-nowrap rounded bg-primary p-2 text-2xs font-medium text-white transition-all delay-500 group-hover:scale-100`}
      >
        {message}
      </span>
    </div>
  );
};

export default Tooltip;
