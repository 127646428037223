import { SubscriptionTypeEnum } from '../types/general';
import { customFetch } from '../utils';

export const createCheckoutSession = async (
  accountId: number,
  subscriptionTypeId: SubscriptionTypeEnum
) => {
  const responseData = await customFetch<{ sessionId: string }>(
    '/api/create-checkout-session',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accountId: accountId,
        subscriptionTypeId,
      }),
    }
  );

  const { sessionId } = responseData;
  return sessionId;
};

export const createCustomerPortalSession = async (customerId: string) => {
  const responseData = await customFetch<{ url: string }>(
    '/api/create-customer-portal-session',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customerId,
      }),
    }
  );

  const { url } = responseData;
  return url;
};
