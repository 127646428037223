'use client';

import React from 'react';
import { useEffect, useRef } from 'react';

export interface TextboxProps {
  text: string;
  onChange?: (text: string) => void;
  placeholder?: string;
  error?: string;
  icon?: React.JSX.Element;
  className?: string;
  fontSize?: 'normal' | 'large';
  autoFocus?: boolean;
  selectAll?: boolean;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
}

const Textbox = ({
  text,
  onChange,
  placeholder,
  error,
  icon,
  className,
  fontSize = 'normal',
  autoFocus,
  selectAll,
  multiline,
  rows,
  disabled,
}: TextboxProps) => {
  const refInput = useRef<HTMLInputElement>(null);
  const refTextArea = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (autoFocus) {
        refInput.current?.focus();
        refTextArea.current?.focus();

        if (selectAll) {
          refInput.current?.select();
          refTextArea.current?.select();
        }
      }
    }, 500);
  }, [autoFocus, placeholder, selectAll]);

  return (
    <div className='flex w-full flex-col gap-2'>
      <div
        className={`flex flex-row items-center gap-2 rounded border p-2 ${
          error ? 'border-red-600' : 'border-separate'
        } ${className || ''}`}
      >
        {multiline ? (
          <textarea
            ref={refTextArea}
            value={text}
            placeholder={placeholder}
            onChange={(e) => onChange?.(e.target.value || '')}
            className={`w-full resize-none text-primary outline-none placeholder:text-secondary ${
              fontSize === 'large'
                ? 'text-sm placeholder:text-sm'
                : 'text-xs placeholder:text-xs'
            }`}
            rows={rows}
            disabled={disabled}
          />
        ) : (
          <>
            {icon}
            <input
              ref={refInput}
              type='text'
              value={text}
              placeholder={placeholder}
              onChange={(e) => onChange?.(e.target.value || '')}
              className={`w-full text-primary outline-none placeholder:text-secondary ${
                fontSize === 'large'
                  ? 'text-sm placeholder:text-sm'
                  : 'text-xs placeholder:text-xs'
              }`}
              disabled={disabled}
            />
          </>
        )}
      </div>
      {error ? (
        <div className={`text-red-600 text-xs ${className}`}>{error}</div>
      ) : null}
    </div>
  );
};

export default Textbox;
