export interface PlusIconProps {
  strokeColor?: string;
  className?: string;
  onClick?: () => void;
}

export default function PlusIcon({
  strokeColor = '#1A1919',
  className,
  onClick,
}: PlusIconProps) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M8 3.3335V12.6668'
        stroke={strokeColor}
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.33331 8H12.6666'
        stroke={strokeColor}
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
