export interface CloseIconProps {
  className?: string;
  onClick?: () => void;
}

export default function CloseIcon({ className, onClick }: CloseIconProps) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M12 4L4 12'
        stroke='#1A1919'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4 4L12 12'
        stroke='#1A1919'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
