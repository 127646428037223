import { PaletteDetail, PaletteType } from '@prisma/client';
import { atom } from 'jotai';
import {
  BeadPalettePreview,
  CustomPaletteDetailDataEx,
  CustomPaletteWithDetails,
  PaletteEx,
  PaletteWithDetails,
} from '../types/palette';

export const paletteTypesAtom = atom<PaletteType[]>([]);
export const palettesAtom = atom<PaletteEx[]>([]);
export const selectedPaletteAtom = atom<PaletteWithDetails | undefined>(
  undefined
);
export const selectedPaletteDetailAtom = atom<PaletteDetail | undefined>(
  undefined
);
export const allPaletteDetailsAtom = atom<PaletteDetail[]>([]);

export const customPalettesAtom = atom<CustomPaletteWithDetails[]>([]);
export const selectedCustomPaletteAtom = atom<
  CustomPaletteWithDetails | undefined
>(undefined);
export const selectedCustomPaletteDetailAtom = atom(new Set<number>());
export const createCustomColorModalVisibleAtom = atom<
  Partial<CustomPaletteDetailDataEx> | undefined
>(undefined);

export const beadPalettePreviewAtom = atom<BeadPalettePreview | undefined>(
  undefined
);
