import {
  deleteCustomPalette,
  fetchCustomPalettes,
  removeCustomPaletteDetail,
} from '@/lib/api/custom-palette';
import {
  COLOR_LINK,
  DEFAULT_FILTER_DEBOUNCE_TIME,
  COLOR_SECONDARY,
} from '@/lib/constants';
import useDebounce from '@/lib/hooks/useDebounce';
import { AnalyticEventNameEnum } from '@/lib/types/general';
import {
  CustomPaletteDetailData,
  CustomPaletteWithDetails,
} from '@/lib/types/palette';
import { isCustomColor, logAnalyticsEvent } from '@/lib/utils';
import { useCallback, useState } from 'react';
import IconButton from '../general/IconButton';
import MyMenu, { MyMenuItem } from '../general/MyMenu';
import Scrollbar from '../general/Scrollbar';
import Textbox from '../general/Textbox';
import CloseIcon from '../icons/CloseIcon';
import DeleteIcon from '../icons/DeleteIcon';
import EditIcon from '../icons/EditIcon';
import PlusIcon from '../icons/PlusIcon';
import SearchIcon from '../icons/SearchIcon';
import HorizontalDivider from '../other/HorizontalDivider';
import { fetchPalettes } from '@/lib/api/palette';
import {
  createPaletteModalVisibleAtom,
  addRemoveToCustomPaletteVisibleAtom,
  confirmModalOptionsAtom,
} from '@/lib/atoms/general';
import {
  createCustomColorModalVisibleAtom,
  customPalettesAtom,
  palettesAtom,
  selectedCustomPaletteAtom,
  selectedCustomPaletteDetailAtom,
} from '@/lib/atoms/palette';
import { useSetAtom, useAtom } from 'jotai';
import Tooltip from '../general/Tooltip';
import CopyIcon from '../icons/CopyIcon';

export interface PaletteEditorPopoverProps {
  onClose: () => void;
}

const PaletteEditorComponent = ({ onClose }: PaletteEditorPopoverProps) => {
  const setCreatePaletteModalVisible = useSetAtom(
    createPaletteModalVisibleAtom
  );

  const setAddRemoveToCustomPaletteVisible = useSetAtom(
    addRemoveToCustomPaletteVisibleAtom
  );
  const setCreateCustomColorModalVisible = useSetAtom(
    createCustomColorModalVisibleAtom
  );
  const setConfirmModalOptions = useSetAtom(confirmModalOptionsAtom);

  const [customPalettes, setCustomPalettes] = useAtom(customPalettesAtom);
  const setPalettes = useSetAtom(palettesAtom);
  const [selectedCustomPalette, setSelectedCustomPalette] = useAtom(
    selectedCustomPaletteAtom
  );
  const setSelectedCustomPaletteDetail = useSetAtom(
    selectedCustomPaletteDetailAtom
  );

  const refreshCustomPalettes = useCallback(async () => {
    const customPalettes = await fetchCustomPalettes();
    setCustomPalettes(customPalettes);

    const palette = await fetchPalettes();
    setPalettes(palette);
  }, [setCustomPalettes, setPalettes]);

  const onCreateCustomPalette = () => {
    setCreatePaletteModalVisible(null);
  };

  const onEditCustomPalette = (customPalette: CustomPaletteWithDetails) => {
    setSelectedCustomPalette(customPalette);
    setSelectedCustomPaletteDetail(new Set());
  };

  const onDeleteCustomPalette = (customPalette: CustomPaletteWithDetails) => {
    setConfirmModalOptions({
      title: 'Delete palette',
      message: `Are you sure you want to delete the palette "${customPalette.customPalette.name}"?`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      onConfirm: async () => {
        await deleteCustomPalette(customPalette.customPalette.id);
        await refreshCustomPalettes();
        setSelectedCustomPalette(undefined);
        logAnalyticsEvent(AnalyticEventNameEnum.DeletePalette, {
          name: customPalette.customPalette.name,
        });
      },
    });
  };

  const onAddColor = () => {
    setAddRemoveToCustomPaletteVisible(selectedCustomPalette);
  };

  const onAddCustomColor = () => {
    setCreateCustomColorModalVisible({
      customPaletteId: selectedCustomPalette?.customPalette.id || 0,
    });
  };

  const onDuplicateCustomPaletteDetail = (detail: CustomPaletteDetailData) => {
    setCreateCustomColorModalVisible({
      customPaletteId: selectedCustomPalette?.customPalette.id || 0,
      paletteDetail: {
        ...detail.paletteDetail,
        name: `${detail.paletteDetail.name} (Copy)`,
      },
    });
  };

  const onEditCustomPaletteDetail = (detail: CustomPaletteDetailData) => {
    setCreateCustomColorModalVisible({
      customPaletteId: selectedCustomPalette?.customPalette.id || 0,
      customPaletteDetailId: detail.id,
      paletteDetail: detail.paletteDetail,
    });
  };

  const onDeleteCustomPaletteDetail = async (
    detail: CustomPaletteDetailData
  ) => {
    setConfirmModalOptions({
      title: 'Delete palette color',
      message: `Are you sure you want to delete the palette color "${detail.paletteDetail.name} - ${detail.paletteDetail.description}"?`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      onConfirm: async () => {
        if (selectedCustomPalette) {
          await removeCustomPaletteDetail(
            selectedCustomPalette.customPalette.id,
            detail.id
          );
          refreshCustomPalettes();

          const newSelectedCustomPalette = {
            ...selectedCustomPalette,
            details: selectedCustomPalette.details.filter(
              (d) => d.id !== detail.id
            ),
          };

          setSelectedCustomPalette(newSelectedCustomPalette);

          logAnalyticsEvent(AnalyticEventNameEnum.DeletePalette, {
            name: detail.paletteDetail.name,
          });
        }
      },
    });
  };

  const onBackButton = () => {
    setSelectedCustomPalette(undefined);
  };

  const menuItems: MyMenuItem[] = [
    {
      value: 'rename',
      label: 'Rename',
      onClick: () => {
        setCreatePaletteModalVisible(selectedCustomPalette);
      },
    },
    {
      value: 'delete',
      label: 'Delete',
      onClick: () => {
        if (selectedCustomPalette) {
          onDeleteCustomPalette(selectedCustomPalette);
        }
      },
    },
  ];

  const PaletteEditorInternalComponent = () => {
    return (
      <div className='flex flex-col rounded-lg border border-divider bg-white pb-4 shadow-md sm:w-[400px]'>
        <div className='flex flex-row items-center justify-between px-4 py-2'>
          <div className='text-sm font-medium'>Palette editor</div>
          <CloseIcon className='cursor-pointer' onClick={() => onClose()} />
        </div>
        <HorizontalDivider />
        <div
          className='mx-4 mb-1 mt-4 flex h-6 cursor-pointer flex-row items-center gap-2'
          onClick={() => onCreateCustomPalette()}
        >
          <PlusIcon className='cursor-pointer' strokeColor={COLOR_LINK} />
          <div className='text-xs font-medium leading-6 text-link'>
            Create new palette
          </div>
        </div>
        <div className='flex flex-col gap-1 px-4 pt-1'>
          {customPalettes.map((customPalette) => (
            <div
              key={customPalette.customPalette.id}
              className='flex h-6 cursor-pointer select-none flex-row items-center justify-between'
            >
              <div className='flex flex-row gap-3'>
                <div className='flex w-9 flex-row'>
                  {customPalette.details
                    .filter((_, idx) => idx < 3)
                    .map((d) => (
                      <div
                        key={d.paletteDetail.color}
                        className='mr-[-4px] h-4 w-4 rounded border border-white'
                        style={{ backgroundColor: d.paletteDetail.color }}
                      />
                    ))}
                </div>
                <div className='text-xs text-primary'>
                  {customPalette.customPalette.name}
                </div>
              </div>
              <div className='flex flex-row gap-4'>
                <EditIcon onClick={() => onEditCustomPalette(customPalette)} />
                <DeleteIcon
                  onClick={() => onDeleteCustomPalette(customPalette)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const PaletteDetailsEditorInternalComponent = () => {
    const [filter, setFilter] = useState<string>('');
    const debouncedFilter = useDebounce(filter, DEFAULT_FILTER_DEBOUNCE_TIME);

    return (
      <div className='flex flex-col rounded-lg border border-divider bg-white pb-4 shadow-md sm:w-[400px]'>
        <div className='flex flex-row items-center justify-between px-4 py-2'>
          <div className='flex flex-row items-center gap-2'>
            <IconButton
              src='assets/icons/back.svg'
              alt='Back'
              onClick={onBackButton}
            />
            <div className='text-sm leading-6'>
              {selectedCustomPalette?.customPalette.name}
            </div>
          </div>
          <div className='flex flex-row gap-3'>
            <MyMenu items={menuItems}>
              <IconButton src='assets/icons/menu.svg' alt='Menu' />
            </MyMenu>
            <CloseIcon className='cursor-pointer' onClick={() => onClose()} />
          </div>
        </div>
        <HorizontalDivider />
        <Textbox
          text={filter}
          onChange={setFilter}
          placeholder='Search palette'
          icon={<SearchIcon strokeColor={COLOR_SECONDARY} />}
          className='border-0 px-4 py-2'
          fontSize={'large'}
          autoFocus
          selectAll
        />
        <HorizontalDivider />
        <div className='flex flex-row items-center gap-4'>
          <div
            className='mx-4 mb-1 mt-4 flex h-6 cursor-pointer flex-row items-center gap-2'
            onClick={() => onAddColor()}
          >
            <PlusIcon className='cursor-pointer' strokeColor={COLOR_LINK} />
            <div className='text-xs font-medium text-link'>
              Add palette color
            </div>
          </div>
          <div
            className='mx-4 mb-1 mt-4 flex h-6 cursor-pointer flex-row items-center gap-2'
            onClick={() => onAddCustomColor()}
          >
            <PlusIcon className='cursor-pointer' strokeColor={COLOR_LINK} />
            <div className='text-xs font-medium text-link'>
              Add custom color
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-1 px-4 pt-1'>
          <Scrollbar className='mr-[-16px] flex h-[355px] flex-col gap-4 pr-4 pt-2 sm:gap-1 sm:pt-0'>
            {selectedCustomPalette?.details
              .filter(
                (d) =>
                  debouncedFilter === '' ||
                  d.paletteDetail.name
                    .toLowerCase()
                    .includes(debouncedFilter.toLowerCase()) ||
                  d.paletteDetail.description
                    .toLowerCase()
                    .includes(debouncedFilter.toLowerCase())
              )
              .map((cpd) => (
                <div
                  key={cpd.id}
                  className='flex h-6 cursor-pointer select-none flex-row items-center justify-between sm:w-[366px]'
                >
                  <div className='flex flex-row gap-3'>
                    <div
                      key={cpd.paletteDetail.color}
                      className='mr-[-4px] h-4 w-4 rounded border border-white'
                      style={{ backgroundColor: cpd.paletteDetail.color }}
                    />
                    <Tooltip message={cpd.paletteDetail.name}>
                      <div className='w-12 truncate text-xs text-primary'>
                        {cpd.paletteDetail.name}
                      </div>
                    </Tooltip>
                    <div
                      className='block max-w-[175px] truncate text-xs text-primary sm:max-w-[265px]'
                      title={cpd.paletteDetail.description}
                    >
                      {cpd.paletteDetail.description}
                    </div>
                  </div>
                  <div className='flex flex-row gap-4'>
                    {isCustomColor(cpd.paletteDetail) && (
                      <>
                        <CopyIcon
                          onClick={() => onDuplicateCustomPaletteDetail(cpd)}
                        />
                        <EditIcon
                          onClick={() => onEditCustomPaletteDetail(cpd)}
                        />
                      </>
                    )}
                    <DeleteIcon
                      onClick={() => onDeleteCustomPaletteDetail(cpd)}
                    />
                  </div>
                </div>
              ))}
          </Scrollbar>
        </div>
      </div>
    );
  };

  return selectedCustomPalette ? (
    <PaletteDetailsEditorInternalComponent />
  ) : (
    <PaletteEditorInternalComponent />
  );
};

export default PaletteEditorComponent;
